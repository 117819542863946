import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import moment from "moment";
import { Modal, Button, Form, Dropdown, Alert } from "react-bootstrap";

import { CustomButton } from "src/shared";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./BettingFormationModal.module.css";

export const BettingFormationModal = observer(({ show, handleClose }) => {
  const { accrualsBetsStore, departamentStore } = useRootStore();
  const [betDate, setBetDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedSubDepartment, setSelectedSubDepartment] = useState(null);

  useEffect(() => {
    departamentStore.loadDepartaments();
  }, [departamentStore]);

  useEffect(() => {
    if (departamentStore.departaments.length === 1) {
      setSelectedDepartment(departamentStore.departaments[0].id);
    }
  }, [departamentStore.departaments]);

  const handleModalClose = () => {
    setErrorMessage(null);
    setBetDate(moment().format("YYYY-MM-DD"));
    setSelectedDepartment("");
    setSelectedSubDepartment(null);
    setComment("");
    handleClose();
  };

  const handleSave = async () => {
    setErrorMessage(null);
    try {
      await accrualsBetsStore.addMonthBet({
        bet_date: moment(betDate).format("YYYY-MM-DD"),
        department_id: selectedDepartment,
        sub_department_id: selectedSubDepartment,
        comment,
      });
      accrualsBetsStore.loadBets();
      handleModalClose();
      if (!accrualsBetsStore.error) {
        handleClose();
      } else {
        setErrorMessage(accrualsBetsStore.error);
      }
    } catch (err) {
      // err
      setErrorMessage(
        accrualsBetsStore.error || "Ошибка при создании месячных ставок.",
      );
    }
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Сформировать ставки</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage(null)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form>
          <Form.Group controlId="betDate">
            <Form.Label>Дата ставок</Form.Label>
            <Form.Control
              type="month"
              value={moment(betDate).format("YYYY-MM")}
              onChange={(e) =>
                setBetDate(new Date(moment(e.target.value, "YYYY-MM")))
              }
              onClick={(e) => e.target.showPicker()}
            />
          </Form.Group>
          <Form.Group controlId="departmentSelect" className="mt-3">
            <Form.Label>Отдел</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={style.dropdownContainer}
              >
                {selectedDepartment
                  ? departamentStore.departaments.find(
                      (dept) => dept.id === selectedDepartment,
                    ).title
                  : "Выберите отдел"}
              </Dropdown.Toggle>
              <Dropdown.Menu className={style.scrollableDropdownMenu}>
                {departamentStore.departaments.map((department) => (
                  <Dropdown.Item
                    key={department.id}
                    onClick={() => {
                      setSelectedDepartment(department.id);
                      setSelectedSubDepartment(null);
                    }}
                  >
                    {department.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group controlId="subDepartmentSelect" className="mt-3">
            <Form.Label>Подотдел</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic-sub-dep"
                className={style.dropdownContainer}
              >
                {selectedSubDepartment
                  ? departamentStore.departaments
                      .find((dept) => dept.id === selectedDepartment)
                      .sub_departments.find(
                        (subDept) => subDept.id === selectedSubDepartment,
                      ).title
                  : "Выберите подотдел"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {selectedDepartment &&
                  departamentStore.departaments
                    .find((dept) => dept.id === selectedDepartment)
                    .sub_departments.map((subDepartment) => (
                      <Dropdown.Item
                        key={subDepartment.id}
                        onClick={() => setSelectedSubDepartment(subDepartment.id)}
                      >
                        {subDepartment.title}
                      </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group controlId="comment" className="mt-3">
            <Form.Label>Примечание</Form.Label>
            <Form.Control
              as="textarea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Введите примечание"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className={style.footerButtons}>
        <Button variant="secondary" onClick={handleModalClose}>
          Отмена
        </Button>
        <CustomButton onClick={handleSave}>Сформировать</CustomButton>
      </Modal.Footer>
    </Modal>
  );
});
