import { makeAutoObservable } from "mobx";
import moment from "moment";

import { getAccruals, getEmployeeAccruals } from "src/shared/api";

export const AllAccrualsStore = () => {
  const store = {
    accruals: [],
    isLoading: false,
    employeeAccruals: null,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalAccruals: 0,
    selectedDepartments: [],
    selectedSubDepartments: [],
    selectedDate: new Date(),
    searchTerm: "",
    selectedPaymentForms: [],
    selectedEmploymentForms: [],

    setEmployeeAccruals(employeeAccruals) {
      this.employeeAccruals = employeeAccruals || null;
    },

    setAccruals(accruals) {
      this.accruals = accruals || [];
    },

    setTotalAccruals(total) {
      this.totalAccruals = total;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSelectedSubDepartments(subDepartments) {
      this.selectedSubDepartments = subDepartments;
    },

    setSelectedDate(date) {
      this.selectedDate = date;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedPaymentForms(forms) {
      this.selectedPaymentForms = forms;
    },

    setSelectedEmploymentForms(forms) {
      this.selectedEmploymentForms = forms;
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSelectedSubDepartments([]);
      this.setSelectedDate(new Date());
      this.setSelectedPaymentForms([]);
      this.setSelectedEmploymentForms([]);
      this.setSearchTerm("");
      this.setCurrentPage(1);
      this.loadAccruals();
    },

    async loadAccruals() {
      try {
        this.setIsLoading(true);

        const filters = new URLSearchParams();
        filters.append("date", moment(this.selectedDate).format("YYYY-MM-01"));
        filters.append("page", this.currentPage);
        filters.append("limit", this.pageSize);

        if (this.searchTerm) {
          filters.append("q", this.searchTerm);
        }

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) =>
            filters.append("department_ids", deptId),
          );
        }

        if (this.selectedSubDepartments.length > 0) {
          this.selectedSubDepartments.forEach((subDeptId) =>
            filters.append("sub_department_ids", subDeptId),
          );
        }

        if (this.selectedPaymentForms.length > 0) {
          this.selectedPaymentForms.forEach((formId) =>
            filters.append("payment_form_ids", formId),
          );
        }

        if (this.selectedEmploymentForms.length > 0) {
          this.selectedEmploymentForms.forEach((formId) =>
            filters.append("employment_form_ids", formId),
          );
        }

        const res = await getAccruals(filters);
        if (res.data) {
          this.setAccruals(res.data.data);
          this.setTotalAccruals(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о начислениях");
      } finally {
        this.setIsLoading(false);
      }
    },

    async loadEmployeeAccruals(
      employee_id,
      department_ids = [],
      sub_department_ids = [],
      date = moment().format("YYYY-MM-DD"),
    ) {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams();
        params.append("date", date);
        if (department_ids.length > 0) {
          department_ids.forEach((deptId) =>
            params.append("department_ids", deptId),
          );
        }
        if (sub_department_ids.length > 0) {
          sub_department_ids.forEach((subDeptId) =>
            params.append("sub_department_ids", subDeptId),
          );
        }
        const res = await getEmployeeAccruals(employee_id, params);
        if (res.data) {
          this.setEmployeeAccruals(res.data);
          this.setError(null);
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о начислениях сотрудника");
      } finally {
        this.setIsLoading(false);
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
