import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";

import { ConfirmationModal, useConfirmModal } from "src/shared";
import { DeleteButton } from "src/shared/components/customButtonTrash";
import style from "src/shared/components/reusableTable/ReusableTable.module.css";
import { useRootStore } from "src/shared/stores/initStore";

import { DealsAddEditModal, useDealsModal } from "../../DealsAddEditModal";

export const AccrualsDealsContent = observer(({ PaginationComponent }) => {
  const { accrualsDealsStore } = useRootStore();
  const [dealToDelete, setDealToDelete] = useState(null);
  const [currentDealEdit, setCurrentDealEdit] = useState(null);
  const { isShow, handleOpen, handleClose } = useDealsModal();
  const { closeConfirmModal, isConfirmModal, showConfirmModal } =
    useConfirmModal();

  const deleteRow = (deal) => {
    deal ? accrualsDealsStore.deleteDeal(deal.id) : null;
    closeConfirmModal();
  };

  const promptDelete = (deal) => {
    if (deal && deal.employee && deal.employee.fullname) {
      showConfirmModal();
      setDealToDelete(deal);
    }
  };

  const handleEdit = (deal) => {
    setCurrentDealEdit(deal);
    handleOpen();
  };

  return (
    <>
      <Table striped bordered hover>
        <tbody>
          {accrualsDealsStore.deals.map((deal) => (
            <tr key={deal.id} onClick={() => handleEdit(deal)}>
              <td className={style.tableTitle}>
                <span>{deal.deal_charge_date}</span>
                <span>{deal.employee.fullname}</span>
                <span>{deal.department.sub_department_name ? `${deal.department.name} - ${deal.department.sub_department_name}` : deal.department.name}</span>
                <span>{deal.unit}</span>
                <span>{deal.cost.toLocaleString()}</span>
                <span>{deal.volume}</span>
                <span>{deal.amount.toLocaleString()}</span>
                <span className={style.commentField}>{deal.comment}</span>
                <span>
                  <DeleteButton handleDelete={() => promptDelete(deal)} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {PaginationComponent}

      {dealToDelete && (
        <ConfirmationModal
          show={isConfirmModal}
          onHide={closeConfirmModal}
          onConfirm={() => deleteRow(dealToDelete)}
          title="Удаление сделки"
        >
          Вы действительно хотите удалить сделку для сотрудника{" "}
          {dealToDelete.employee.fullname} на сумму{" "}
          {dealToDelete.amount.toLocaleString()} от{" "}
          {dealToDelete.deal_charge_date}?
        </ConfirmationModal>
      )}

      {isShow && (
        <DealsAddEditModal
          show={isShow}
          isEdit={currentDealEdit != null}
          deal={currentDealEdit}
          handleClose={handleClose}
        />
      )}
    </>
  );
});
