import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Table } from "react-bootstrap";

import { ConfirmationModal, useConfirmModal, DeleteButton } from "src/shared";
import style from "src/shared/components/betsTable/BetsTable.module.css";
import { useRootStore } from "src/shared/stores/initStore";

import { BetsAddEditModal, useBetsModal } from "../../addEditModal";
import { deleteRow } from "../lib/deleteBet";

const BetsContent = observer(() => {
  const { accrualsBetsStore } = useRootStore();
  const { isShow, handleOpen, handleClose } = useBetsModal();
  const [betToDelete, setBetToDelete] = useState(null);
  const [currentBetEdit, setCurrentBetEdit] = useState(null);
  const { closeConfirmModal, isConfirmModal, showConfirmModal } =
    useConfirmModal();

  const promptDelete = (bet) => {
    if (bet && bet.employee.fullname && bet.bets_day) {
      showConfirmModal();
      setBetToDelete(bet);
    }
  };

  const handleEdit = (bet) => {
    setCurrentBetEdit(bet);
    handleOpen();
  };

  return (
    <>
      <Table striped bordered hover>
        <tbody>
          {accrualsBetsStore.bets.map((bet) => (
            <tr key={bet.id} onClick={() => handleEdit(bet)}>
              <td className={style.tableTitle}>
                <span>{bet.employee.fullname}</span>
                <span>{bet.department.sub_department_name ? `${bet.department.name} (${bet.department.sub_department_name})` : bet.department.name}</span>
                <span>{bet.employee.job_title || "-"}</span>
                <span>{bet.bets_num}</span>
                <span>{bet.bets_price}</span>
                <span>{bet.amount}</span>
                <span className={style.commentField}>{bet.comment}</span>
                <span>
                  <DeleteButton handleDelete={() => promptDelete(bet)} />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isShow && (
        <BetsAddEditModal
          show={isShow}
          isEdit={currentBetEdit != null}
          bet={currentBetEdit}
          handleClose={handleClose}
        />
      )}

      {betToDelete && (
        <ConfirmationModal
          show={isConfirmModal}
          onHide={closeConfirmModal}
          title="Удаление ставки"
          onConfirm={() =>
            deleteRow(betToDelete, accrualsBetsStore, closeConfirmModal)
          }
        >
          Вы действительно хотите удалить для сотрудника{" "}
          {betToDelete?.employee?.fullname} начисление по ставке{" "}
          {betToDelete?.amount.toLocaleString()} за {betToDelete?.bets_day}?
        </ConfirmationModal>
      )}
    </>
  );
});

export default BetsContent;
