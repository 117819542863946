import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
  getBets,
  createBet,
  createMonthBet,
  updateBet,
  deleteBet,
} from "src/shared/api";

export const AccrualsBetsStore = () => {
  const store = {
    bets: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalBets: 0,
    selectedDepartments: [],
    selectedSubDepartments: [],
    searchTerm: "",
    selectedMonth: new Date(),

    setBets(bets) {
      this.bets = bets || [];
    },

    setTotalBets(total) {
      this.totalBets = total;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSelectedSubDepartments(subDepartments) {
      this.selectedSubDepartments = subDepartments;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedMonth(date) {
      this.selectedMonth = date;
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSelectedSubDepartments([]);
      this.setSearchTerm("");
      this.setSelectedMonth(new Date());
      this.setCurrentPage(1);
      this.loadBets();
    },

    async loadBets() {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams({
          date: moment(this.selectedMonth).format("YYYY-MM-DD"),
          // date: this.selectedMonth.toISOString().split("T")[0],
          q: this.searchTerm,
          page: this.currentPage,
          limit: this.pageSize,
        });

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) => {
            params.append("department_ids", deptId);
          });
        }

        if (this.selectedSubDepartments.length > 0) {
          this.selectedSubDepartments.forEach((subDeptId) => {
            params.append("sub_department_ids", subDeptId);
          });
        }

        const res = await getBets(params);
        if (res.data) {
          this.setBets(res.data.data);
          this.setTotalBets(res.data.total);
          this.setError(null);
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о ставках");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addBet(data) {
      this.setIsLoading(true);
      try {
        await createBet(data);
        this.setBets([...this.bets]);
        this.setError(null);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при добавлении данных о ставке");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async addMonthBet(data) {
      this.setIsLoading(true);
      try {
        await createMonthBet(data);
        this.setBets([...this.bets]);
        this.setError(null);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при добавлении месячной ставки");
        }
        throw error;
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateBet(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateBet(id, data);
        if (res.data) {
          this.setBets(
            this.bets.map((bet) => (bet.id === id ? res.data : bet)),
          );
          this.setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail.message);
        } else {
          this.setError("Ошибка при обновлении данных о ставке");
        }
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteBet(id) {
      try {
        await deleteBet(id);
        this.setBets(this.bets.filter((bet) => bet.id !== id));
      } catch (error) {
        this.setError("Ошибка при удалении данных о ставке");
      }
    },
  };

  makeAutoObservable(store);
  return store;
};
