import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Modal, Button, Form, Dropdown, Alert } from "react-bootstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import { CustomButton } from "src/shared/components";
import { useRootStore } from "src/shared/stores/initStore";
import { FormDropdown, FormGroup } from "src/shared/ui/controls";

import style from "./index.module.css";
import { handleTaxpayerIdChange } from "./lib";

export const EditEmployee = observer((props) => {
  const taxPayerIdLength = 12;
  const {
    employmentFormsStore,
    paymentFormsStore,
    departamentStore,
    authStore,
  } = useRootStore();
  const userRole = authStore.userData?.profile?.role;
  const isAccountant = userRole === "accountant";

  const { initialData } = props;

  const [formData, setFormData] = useState({
    fullname: initialData ? initialData.fullname : "",
    nickname: initialData ? initialData.nickname : "",
    email: initialData ? initialData.email : "",
    salary: initialData ? initialData.salary : 0,
    regular_surcharge: initialData ? initialData.regular_surcharge : 0,
    regular_bets_num: initialData ? initialData.regular_bets_num : 0,
    regular_bets_price: initialData ? initialData.regular_bets_price : 0,
    payment_form: initialData ? initialData.payment_form : null,
    employment_form: initialData ? initialData.employment_form : null,
    departments: initialData ? initialData.departments : [],
    job_title: initialData ? initialData.job_title : "",
    taxpayer_id: initialData ? initialData.taxpayer_id : null,
    is_fired: initialData ? initialData.is_fired : false,
    meta_employee: initialData && initialData.meta_employee !== "NULL" ? initialData.meta_employee : null,
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [multiSelections, setMultiSelections] = useState([]);

  useEffect(() => {
    employmentFormsStore.loadEmployments();
    paymentFormsStore.loadForms();
    const initialDepartments = initialData.employee_department_info ? initialData.employee_department_info : [];
    initialDepartments.forEach((dept) => {
      const mapKey = dept.sub_department_title ? `${dept.department_title} - ${dept.sub_department_title}` : dept.department_title;
      setDepartmentOptions((prev) => [...prev, { title: mapKey, departmentId: dept.department_id, subDepartmentId: dept.sub_department_id }]);
      setMultiSelections((prev) => [...prev, { title: mapKey, departmentId: dept.department_id, subDepartmentId: dept.sub_department_id }]);
    });
  }, [employmentFormsStore, paymentFormsStore, departamentStore, initialData]);

  const validateTaxpayerId = (value) => {
    if (!value) return true;
    return value && value.length === taxPayerIdLength;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelect = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!validateTaxpayerId(formData.taxpayer_id)) {
      setErrorMessage(`ИНН должен состоять из ${taxPayerIdLength} цифр`);
      return;
    };

    setErrorMessage(null);

    const departments = multiSelections.map((dept) => ({
      id: dept.departmentId,
      sub_department_id: dept.subDepartmentId,
    }));

    props.onSave({
      ...formData,
      employment_form_id: formData.employment_form?.id,
      payment_form_id: formData.payment_form?.id,
      department_ids: departments,
    });
  };

  const handleDepartmentSelection = (selected) => {
    let duplicate = false;
    if (selected.length > multiSelections.length) {
      const newSelected = selected.filter((item) => !multiSelections.includes(item));
      const duplicateByDepartment = multiSelections.filter((item) => newSelected[0].departmentId === item.departmentId);
      duplicate = duplicateByDepartment.length > 0;
    };
    if (duplicate) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      setErrorMessage("Запись о сотруднике может быть добавлена только в один отдел или его подотдел");
      setMultiSelections(multiSelections);
      return;
    };
    setMultiSelections(selected);
  };

  const handleDepartmentSearch = (query) => {
    setIsLoading(true);
    const prevPageSize = departamentStore.pageSize;
    departamentStore.setWithSubDepartments(true);
    departamentStore.setPageSize(500);
    departamentStore.setSearchTerm(query);

    setDepartmentOptions([]);
    departamentStore.departaments.forEach((dept) => {
      setDepartmentOptions((prevOptions) => [...prevOptions, { title: dept.title, departmentId: dept.id }]);
      dept.sub_departments.forEach((subDept) => {
        const mapKey = `${dept.title} - ${subDept.title}`;
        setDepartmentOptions((prevOptions) => [...prevOptions, { title: mapKey, departmentId: dept.id, subDepartmentId: subDept.id }]);
      });
    });

    departamentStore.setWithSubDepartments(false);
    departamentStore.setPageSize(prevPageSize);
    setIsLoading(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      className={style.modalContainer}
    >
      <Modal.Header closeButton>
        <Modal.Title>Редактирование сотрудника</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage(null)}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <FormGroup
            controlId="formFullName"
            label="ФИО"
            type="text"
            name="fullname"
            required
            value={formData.fullname}
            onChange={handleChange}
            placeholder="Введите полное имя"
          />

          <FormGroup
            controlId="formNickname"
            label="Никнейм"
            type="text"
            name="nickname"
            required={false}
            value={formData.nickname}
            onChange={handleChange}
            placeholder="Введите никнейм"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formMetaInfo"
            label="Мета информация"
            type="text"
            name="meta_employee"
            required={false}
            value={formData.meta_employee}
            onChange={handleChange}
            placeholder="Введите  мета информацию"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formEmail"
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Введите email"
            className={style.styleFormMargin}
          />

          {isAccountant && (
            <FormGroup
              controlId="formTaxpayerId"
              label="ИНН"
              type="text"
              name="taxpayer_id"
              value={formData.taxpayer_id || ""}
              onChange={(e) => handleTaxpayerIdChange(e, formData, setFormData, taxPayerIdLength)}
              placeholder="Введите ИНН"
              className={style.styleFormMargin}
              maxLength={taxPayerIdLength}
            />
          )}

          <FormGroup
            controlId="formSalary"
            label="Оклад"
            type="number"
            min="0"
            step="0.01"
            name="salary"
            required
            value={formData.salary}
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularSurcharge"
            label="Постоянная доплата"
            type="number"
            min="0"
            step="0.01"
            name="regular_surcharge"
            required
            value={formData.regular_surcharge}
            placeholder="Введите доплату"
            onChange={handleChange}
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsNum"
            label="Постоянное кол-во ставок"
            type="number"
            min="0"
            name="regular_bets_num"
            value={formData.regular_bets_num}
            onChange={handleChange}
            placeholder="Введите кол-во ставок"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formRegularBetsPrice"
            label="Цена ставки по-умолчанию"
            type="number"
            min="0"
            step="0.01"
            name="regular_bets_price"
            value={formData.regular_bets_price || ""}
            onChange={handleChange}
            placeholder="Введите цену ставки"
            className={style.styleFormMargin}
          />

          <FormGroup
            controlId="formJobTitle"
            label="Должность"
            type="text"
            name="job_title"
            required={false}
            value={formData.job_title}
            onChange={handleChange}
            placeholder="Введите должность"
            className={style.styleFormMargin}
          />

          {userRole !== "department_head" && (
            <>
              <FormDropdown
                controlId="formPaymentForm"
                label="Форма выплаты"
                options={paymentFormsStore.forms}
                selectedOption={formData.payment_form}
                onSelect={(selectedOption) =>
                  handleSelect("payment_form", selectedOption)
                }
                className={style.styleFormMargin}
                placeholder="Выберите форму выплаты"
              />

              <FormDropdown
                controlId="formEmploymentForm"
                label="Форма занятости"
                options={employmentFormsStore.employments}
                selectedOption={formData.employment_form}
                onSelect={(selectedOption) =>
                  handleSelect("employment_form", selectedOption)
                }
                className={style.styleFormMargin}
                placeholder="Выберите форму ТУ"
              />

              <Form.Group
                controlId="formDepartments"
                className={style.styleFormMargin}
              >
                <Form.Label>Отделы</Form.Label>
                <AsyncTypeahead
                  id="departments"
                  labelKey="title"
                  multiple
                  onChange={handleDepartmentSelection}
                  options={departmentOptions}
                  placeholder="Выберите отделы и подотделы"
                  minLength={2}
                  onSearch={handleDepartmentSearch}
                  isLoading={isLoading}
                  selected={multiSelections}
                />
              </Form.Group>
            </>
          )}

          <Form.Group
            controlId="formDismissed"
            className={style.styleFormMargin}
          >
            <Form.Check
              type="checkbox"
              label="Уволен"
              name="is_fired"
              checked={formData.is_fired}
              onChange={handleChange}
            />
          </Form.Group>
          <div className={style.btnContainer}>
            <CustomButton onClick={props.onHide}>Закрыть</CustomButton>
            <CustomButton type="submit">Сохранить</CustomButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
});
