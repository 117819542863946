import { makeAutoObservable } from "mobx";

import {
  createEmployee,
  deleteEmployee,
  getEmployees,
  updateEmployee,
  getEmployee,
} from "src/shared/api/employees";

export const EmployeesStore = () => {
  const store = {
    employees: [],
    employee: null,
    isLoading: false,
    error: null,
    total: 0,
    currentPage: 1,
    pageSize: 20,
    searchTerm: "",
    selectedDepartments: [],
    selectedSubDepartments: [],
    selectedPaymentForm: "",
    selectedEmploymentForm: "",
    firedOnly: false,
    employeeDepartmentDetails: false,

    setEmployee(employee) {
      this.employee = employee;
    },

    setEmployees(employees, total) {
      this.employees = employees || [];
      this.total = total || 0;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },
    
    setEmployeeDepartmentDetails(details) {
      this.employeeDepartmentDetails = details;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSelectedSubDepartments(subDepartments) {
      this.selectedSubDepartments = subDepartments;
    },

    setSelectedPaymentForm(paymentForm) {
      this.selectedPaymentForm = paymentForm;
    },

    setSelectedEmploymentForm(employmentForm) {
      this.selectedEmploymentForm = employmentForm;
    },

    setFiredOnly(firedOnly) {
      this.firedOnly = firedOnly;
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSelectedSubDepartments([]);
      this.setSearchTerm("");
      this.setSelectedPaymentForm("");
      this.setSelectedEmploymentForm("");
      this.setFiredOnly(false);
      this.setCurrentPage(1);
      this.loadEmployees();
    },

    async loadEmployees() {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams();

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) => {
            params.append("department_ids", deptId);
          });
        }
        if (this.selectedSubDepartments.length > 0) {
          this.selectedSubDepartments.forEach((deptId) => {
            params.append("sub_department_ids", deptId);
          });
        }
        if (this.selectedEmploymentForm)
          params.append("employment_form", this.selectedEmploymentForm);
        if (this.searchTerm) params.append("q", this.searchTerm);

        if (this.selectedPaymentForm)
          params.append("payment_form", this.selectedPaymentForm);

        if (this.employeeDepartmentDetails)
          params.append("employee_department_details", this.employeeDepartmentDetails);

        params.append("fired_only", this.firedOnly);
        params.append("page", this.currentPage);
        params.append("limit", this.pageSize);
        const response = await getEmployees(params);
        if (response.data) {
          this.setEmployees(response.data.data, response.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке сотрудников");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addEmployee(data) {
      this.setIsLoading(true);
      try {
        const res = await createEmployee(data);
        if (res.data && res.data.id) {
          this.employees.push(res.data);
          this.setError(null);
          return res.data;
        }
      } catch (error) {
        this.setError("Ошибка при добавлении сотрудника");
      } finally {
        this.setIsLoading(false);
      }
    },
    async updateEmployee(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateEmployee(id, data);
        this.employees = this.employees.map((emp) =>
          emp.id === id ? { ...emp, ...res.data } : emp,
        );
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при обновлении данных сотрудника");
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteEmployee(id) {
      this.setIsLoading(true);
      try {
        await deleteEmployee(id);
        this.employees = this.employees.filter((emp) => emp.id !== id);
        this.setError(null);
      } catch (error) {
        this.setError("Ошибка при удалении сотрудника");
      } finally {
        this.setIsLoading(false);
      }
    },

    getEmployeesByDepartment(departmentId) {
      return this.employees.filter((employee) =>
        employee.departments.some((dept) => dept.id === departmentId),
      );
    },

    async loadEmployee(id) {
      try {
        this.setIsLoading(true);
        const res = await getEmployee(id);
        if (res.data) {
          this.setEmployees(res.data);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных сотрудника");
      } finally {
        this.setIsLoading(false);
      }
    }
  };

  makeAutoObservable(store);
  return store;
};
