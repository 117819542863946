import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
  getSurcharges,
  getExtraPaymentsTypes,
  createSurcharge,
  updateSurcharge,
  deleteSurcharge,
  createMonthSurcharge,
} from "src/shared/api";

export const ExtraPaymentsStore = () => {
  const store = {
    surcharges: [],
    extraPaymentsTypes: [],
    isLoading: false,
    error: null,
    currentPage: 1,
    pageSize: 20,
    totalSurcharges: 0,
    selectedDepartments: [],
    selectedSubDepartments: [],
    searchTerm: "",
    selectedMonth: new Date(),

    setSurcharges(surcharges) {
      this.surcharges = surcharges || [];
    },

    setExtraPaymentsTypes(extraPaymentsTypes) {
      this.extraPaymentsTypes = extraPaymentsTypes;
    },

    setTotalSurcharges(total) {
      this.totalSurcharges = total;
    },

    setIsLoading(isLoading) {
      this.isLoading = isLoading;
    },

    setError(error) {
      this.error = error;
    },

    clearError() {
      this.setError(null);
    },

    setCurrentPage(page) {
      this.currentPage = page;
    },

    setPageSize(size) {
      this.pageSize = size;
    },

    setSelectedDepartments(departments) {
      this.selectedDepartments = departments;
    },

    setSelectedSubDepartments(subDepartments) {
      this.selectedSubDepartments = subDepartments;
    },

    setSearchTerm(term) {
      this.searchTerm = term;
    },

    setSelectedMonth(date) {
      this.selectedMonth = date;
    },

    resetFilters() {
      this.setSelectedDepartments([]);
      this.setSelectedSubDepartments([]);
      this.setSearchTerm("");
      this.setSelectedMonth(new Date());
      this.setCurrentPage(1);
      this.loadSurcharges();
    },

    async loadSurcharges() {
      try {
        this.setIsLoading(true);
        const params = new URLSearchParams({
          date: moment(this.selectedMonth).format("YYYY-MM-DD"),
          q: this.searchTerm,
          page: this.currentPage,
          limit: this.pageSize,
        });

        if (this.selectedDepartments.length > 0) {
          this.selectedDepartments.forEach((deptId) => {
            params.append("department_ids", deptId);
          });
        }

        if (this.selectedSubDepartments.length > 0) {
          this.selectedSubDepartments.forEach((subDeptId) => {
            params.append("sub_department_ids", subDeptId);
          });
        }

        const res = await getSurcharges(params);
        if (res.data) {
          this.setSurcharges(res.data.data);
          this.setTotalSurcharges(res.data.total);
          this.clearError();
        }
      } catch (error) {
        this.setError("Ошибка при загрузке данных о надбавках");
      } finally {
        this.setIsLoading(false);
      }
    },

    async addMonthSurcharge(data) {
      this.setIsLoading(true);
      try {
        await createMonthSurcharge(data);
        this.setSurcharges([...this.surcharges]);
        this.setError(null);
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.setError(error.response.data.detail);
        } else {
          this.setError("Ошибка при добавлении месячной доплаты");
        }
        throw error;
      } finally {
        this.setIsLoading(false);
      }
    },

    async getExtraPaymentsTypes(type) {
      try {
        const res = await getExtraPaymentsTypes(type);
        this.setExtraPaymentsTypes(res.data.types);
        this.setError(null);
      } catch (error) {
        console.error("Ошибка при загрузке типов доплат:", error);
        this.setError("Ошибка при загрузке типов доплат");
      }
    },

    async addSurcharge(data) {
      this.setIsLoading(true);
      try {
        const res = await createSurcharge(data);
        if (res.data && res.data.id) {
          this.surcharges.push(res.data);
          this.clearError();
          await this.loadSurcharges();
          return res.data;
        }
      } catch (error) {
        this.setError("Ошибка при добавлении надбавки");
      } finally {
        this.setIsLoading(false);
      }
    },

    async updateSurcharge(id, data) {
      this.setIsLoading(true);
      try {
        const res = await updateSurcharge(id, data);
        this.surcharges = this.surcharges.map((surcharge) =>
          surcharge.id === id ? { ...surcharge, ...res.data } : surcharge,
        );
        this.clearError();
      } catch (error) {
        this.setError("Ошибка при обновлении надбавки");
      } finally {
        this.setIsLoading(false);
      }
    },

    async deleteSurcharge(id) {
      this.setIsLoading(true);
      try {
        await deleteSurcharge(id);
        this.surcharges = this.surcharges.filter(
          (surcharge) => surcharge.id !== id,
        );
        this.clearError();
      } catch (error) {
        this.setError("Ошибка при удалении надбавки");
      } finally {
        this.setIsLoading(false);
      }
    },
  };
  makeAutoObservable(store);
  return store;
};
