import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { FormControl, Stack } from "react-bootstrap";

import {
  useDebounce,
  useSetTitle,
  CustomButton,
  PaginationComponent,
  ReusableBootstrapMonthPicker,
  useSizePageDepartment,
  DepartmentFilterDropdown,
  SubDepartmentFilterDropdown,
} from "src/shared";
import { BetsTable } from "src/shared/components/betsTable";
import { useRootStore } from "src/shared/stores/initStore";

import style from "./AccrualsBets.module.css";
import { BetsAddEditModal, useBetsModal } from "./addEditModal";
import { BettingFormationModal } from "./bettingFormationModal/ui/BettingFormationModal";
import { BetsContent } from "./content/ui";
import { thBetsArray } from "../lib";

export const AccrualsBets = observer(() => {
  useSetTitle("Начисления - ставки");
  const { departamentStore, accrualsBetsStore } = useRootStore();
  const { isShow, handleOpen, handleClose } = useBetsModal();
  const [showFormationModal, setShowFormationModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useSizePageDepartment();

  useEffect(() => {
    departamentStore.loadDepartaments();
    accrualsBetsStore.setSelectedDepartments([]);
    accrualsBetsStore.loadBets();
  }, [departamentStore, accrualsBetsStore]);

  useEffect(() => {
    accrualsBetsStore.setSearchTerm(debouncedSearchTerm);
    accrualsBetsStore.loadBets();
  }, [debouncedSearchTerm, accrualsBetsStore]);

  const handlePageChange = (page) => {
    accrualsBetsStore.setCurrentPage(page);
    accrualsBetsStore.loadBets();
  };

  const handlePageSizeChange = (size) => {
    accrualsBetsStore.setPageSize(size);
    accrualsBetsStore.setCurrentPage(1);
    accrualsBetsStore.loadBets();
  };

  const handleDepartmentToggle = (deptId) => {
    const newSelectedDepartments =
      accrualsBetsStore.selectedDepartments.includes(deptId)
        ? accrualsBetsStore.selectedDepartments.filter((id) => id !== deptId)
        : [...accrualsBetsStore.selectedDepartments, deptId];

    const newSelectedDepartmentsSubIds = departamentStore.departaments.filter(
      (department) => newSelectedDepartments.includes(department.id),
    ).map((department) => department.sub_departments.map((subDepartment) => subDepartment.id)).flat();

    const newSelectedSubDepartments = accrualsBetsStore.selectedSubDepartments.filter(
      (subDepartmentId) => newSelectedDepartmentsSubIds.includes(subDepartmentId),
    );
    
    accrualsBetsStore.setSelectedDepartments(newSelectedDepartments);
    accrualsBetsStore.setSelectedSubDepartments(newSelectedSubDepartments);
    accrualsBetsStore.loadBets();
  };

  const handleDepartmentReset = () => {
    accrualsBetsStore.setSelectedDepartments([]);
    accrualsBetsStore.loadBets();
  };

  const handleSubDepartmentToggle = (subDeptId) => {
    const newSelectedSubDepartments =
    accrualsBetsStore.selectedSubDepartments.includes(subDeptId)
        ? accrualsBetsStore.selectedSubDepartments.filter((id) => id !== subDeptId)
        : [...accrualsBetsStore.selectedSubDepartments, subDeptId];

    accrualsBetsStore.setSelectedSubDepartments(newSelectedSubDepartments);
    accrualsBetsStore.loadBets();
  };

  const handleSubDepartmentReset = () => {
    accrualsBetsStore.setSelectedSubDepartments([]);
    accrualsBetsStore.loadBets();
  };

  const handleMonthChange = (selectedDate) => {
    accrualsBetsStore.setSelectedMonth(selectedDate);
    accrualsBetsStore.loadBets();
  };

  const toggleFilters = () => setFiltersVisible((prev) => !prev);

  return (
    <>
      <Stack gap={3}>
        <div className={style.buttonsContainer}>
          <div className={style.buttons}>
            <CustomButton onClick={handleOpen}>Добавить ставку</CustomButton>
            <CustomButton onClick={() => setShowFormationModal(true)}>
              Сформировать ставки
            </CustomButton>
            <CustomButton onClick={toggleFilters}>
              {filtersVisible ? "Скрыть фильтры" : "Показать фильтры"}
            </CustomButton>
          </div>

          {filtersVisible && (
            <div className={style.filters}>
              <ReusableBootstrapMonthPicker
                selectedMonth={accrualsBetsStore.selectedMonth}
                onChange={handleMonthChange}
              />

              <DepartmentFilterDropdown
                selectedDepartments={accrualsBetsStore.selectedDepartments}
                onToggleDepartment={handleDepartmentToggle}
                onResetDepartments={handleDepartmentReset}
              />

              <SubDepartmentFilterDropdown
                selectedSubDepartments={accrualsBetsStore.selectedSubDepartments}
                onToggleSubDepartment={handleSubDepartmentToggle}
                onResetSubDepartments={handleSubDepartmentReset}
                selectedDepartments={accrualsBetsStore.selectedDepartments}
                departaments={departamentStore.departaments}
              />

              <FormControl
                className={style.searchInput}
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className={style.leftSide}>
                <CustomButton onClick={() => accrualsBetsStore.resetFilters()}>
                  Сбросить фильтры
                </CustomButton>
              </div>
            </div>
          )}
        </div>

        <BetsTable thArray={thBetsArray}>
          <BetsContent />
        </BetsTable>

        <PaginationComponent
          itemsPerPage={accrualsBetsStore.pageSize}
          totalItems={accrualsBetsStore.totalBets}
          paginate={handlePageChange}
          currentPage={accrualsBetsStore.currentPage}
          onPageSizeChange={handlePageSizeChange}
        />
      </Stack>

      <BetsAddEditModal show={isShow} handleClose={handleClose} bet={null} />
      <BettingFormationModal
        show={showFormationModal}
        handleClose={() => setShowFormationModal(false)}
      />
    </>
  );
});
