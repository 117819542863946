import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { Dropdown } from "react-bootstrap";

import style from "./SubDepartmentFilterDropdown.module.css";

const SubDepartmentFilterDropdown = observer(
  ({ selectedSubDepartments, onToggleSubDepartment, onResetSubDepartments, selectedDepartments, departaments }) => {
    const [filteredDepartments, setFilteredDepartments] = useState([]);

    useEffect(() => {
      const updatedDepartments = selectedDepartments.length > 0
        ? departaments.filter(department =>
            selectedDepartments.includes(department.id))
        : departaments;
      setFilteredDepartments(updatedDepartments);
    }, [selectedDepartments, departaments]);

    return (
      <Dropdown autoClose="outside">
        <Dropdown.Toggle className={style.dropdownContainer}>
          {selectedSubDepartments.length === 0
            ? "Выберите подотделы"
            : `Выбрано подотделов: ${selectedSubDepartments.length}`}
        </Dropdown.Toggle>
        <Dropdown.Menu className={style.scrollableDropdownMenu}>
          {filteredDepartments.map((department) => (
            department.sub_departments.map((subDepartment) => (
              <Dropdown.Item
                key={subDepartment.id}
                onClick={() => onToggleSubDepartment(subDepartment.id)}
                active={selectedSubDepartments.includes(subDepartment.id)}
              >
                {department.title} - {subDepartment.title ? subDepartment.title : "Без названия"}
              </Dropdown.Item>
            ))
          ))}
          <Dropdown.Divider />
          <Dropdown.Item onClick={onResetSubDepartments}>
            Сбросить фильтры
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  },
);

export default SubDepartmentFilterDropdown;
