import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import { Form, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CustomButton from "src/shared/components/customButton/CustomButton";
import { DeleteButton } from "src/shared/components/customButtonTrash";
import { useRootStore } from "src/shared/stores/initStore";
import { FormGroup } from "src/shared/ui/controls";
import { Header } from "src/shared/ui/pageTitle";
import { PageWrapper } from "src/shared/ui/pageWrapper";

import style from "./style.module.css";
import {
  getDepartmentHeads,
  addSelectedHead,
  removeSelectedHead,
  handleSubmitDepartment,
} from "../lib/utils";

const DepartmentCreate = observer(() => {
  const { usersStore, departamentStore } = useRootStore();
  const [name, setName] = useState("");
  const [selectedHeads, setSelectedHeads] = useState([]);
  const [subDeps, setSubDeps] = useState([]);
  const [newSubDep, setNewSubDep] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    usersStore.loadUsers();
  }, [usersStore]);

  const departmentHeads = getDepartmentHeads(usersStore.users);

  const handleSelectHead = (headId) => {
    setSelectedHeads(addSelectedHead(selectedHeads, headId));
  };

  const handleRemoveHead = (headId) => {
    setSelectedHeads(removeSelectedHead(selectedHeads, headId));
  };

  const handleAddSubDep = () => {
    if (newSubDep.trim() && !subDeps.includes(newSubDep.trim())) {
      setSubDeps((prev) => [...prev, newSubDep.trim()]);
      setNewSubDep("");
    }
  };

  const handleRemoveSubDep = (subDep) => {
    setSubDeps((prev) => prev.filter((dep) => dep !== subDep));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const departmentData = {
      title: name,
      heads_ids: selectedHeads,
      sub_departments: subDeps,
    };
    if (await handleSubmitDepartment(departamentStore, departmentData)) {
      navigate("/departament");
    }
  };

  return (
    <PageWrapper>
      <Header text="Добавить отдел" />
      <Form onSubmit={handleSubmit} className={style.formContainer}>
        <CustomButton type="submit">Создать</CustomButton>

        <FormGroup
          label="Название отдела"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Введите название отдела"
          required
        />

        <Form.Group>
          <Form.Label>Руководители отдела</Form.Label>

          <Dropdown autoClose="outside">
            <Dropdown.Toggle className={style.dropdownContainer}>
              Выберите руководителей
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {departmentHeads.map((head) => (
                <Dropdown.Item
                  key={head.id}
                  onClick={() => handleSelectHead(head.id)}
                >
                  {head.profile.full_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div
            className={
              selectedHeads.length > 0
                ? style.personsContainer
                : style.emptyContainer
            }
          >
            {selectedHeads.map((id) => (
              <div key={id} className={style.personContainer}>
                <div className={style.content}>
                  {
                    departmentHeads.find((head) => head.id === id)?.profile
                      .full_name
                  }
                </div>
                <DeleteButton handleDelete={() => handleRemoveHead(id)} />
              </div>
            ))}
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Подотделы</Form.Label>
          <div className={style.subDepartmentInputContainer}>
            <Form.Control
              type="text"
              placeholder="Введите название подотдела"
              value={newSubDep}
              onChange={(e) => setNewSubDep(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleAddSubDep()}
            />
            <CustomButton onClick={handleAddSubDep} disabled={!newSubDep.trim()}>
              Добавить
            </CustomButton>
          </div>
          <div className={
            subDeps.length > 0
              ? style.personsContainer
              : style.emptyContainer
          }>
            {subDeps.map((subDep) => (
              <div key={subDep} className={style.personContainer}>
                <div className={style.content}>{subDep}</div>
                <DeleteButton handleDelete={() => handleRemoveSubDep(subDep)} />
              </div>
            ))}
          </div>
        </Form.Group>

      </Form>
    </PageWrapper>
  );
});

export default DepartmentCreate;
