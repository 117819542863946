import { useState, useEffect } from "react";

import { observer } from "mobx-react";
import { Form, Dropdown, Table, Alert, Modal, Container } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import { icClose } from "src/assets";
import { useSetTitle } from "src/shared";
import CustomButton from "src/shared/components/customButton/CustomButton";
import { DeleteButton } from "src/shared/components/customButtonTrash";
import Spinner from "src/shared/components/loaders/Spinner";
import PaginationPayment from "src/shared/components/paginations/PaginationComponent";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useRootStore } from "src/shared/stores/initStore";
import { FormGroup } from "src/shared/ui/controls";
import { AddEmployee } from "src/shared/ui/modals/employees/AddEmployee";
import { Header } from "src/shared/ui/pageTitle";
import { PageWrapper } from "src/shared/ui/pageWrapper";

import style from "./index.module.css";

const DepartmentEdit = observer(() => {
  const { departmentId } = useParams();
  const { departamentStore, usersStore, employeesStore } = useRootStore();
  const [department, setDepartment] = useState({
    title: "",
    heads: [],
    employees: [],
  });
  const [selectedHeads, setSelectedHeads] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [initialEmployees, setInitialEmployees] = useState([]);

  const [newSubDep, setNewSubDep] = useState("");
  const [subDeps, setSubDeps] = useState([]);
  const [subDepErrorMessage, setSubDepErrorMessage] = useState(null);
  const [showSubDepartmentDeleteModal, setShowSubDepartmentDeleteModal] = useState(false);
  const [toDeleteSubDepId, setToDeleteSubDepId] = useState(null);
  const [toDeleteSubDepTitle, setToDeleteSubDepTitle] = useState(null);
  const [showSubDepartmentEditModal, setShowSubDepartmentEditModal] = useState(false);
  const [toEditSubDepId, setToEditSubDepId] = useState(null);
  const [toEditSubDepTitle, setToEditSubDepTitle] = useState(null);
  const [editSubDepErrorMessage, setEditSubDepErrorMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [search, setSearch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [searchSelected, setSearchSelected] = useState("");
  const debouncedSearch = useDebounce(search, 600);
  const debouncedSearchSelected = useDebounce(searchSelected, 600);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastEmployee = currentPage * itemsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;

  const filteredSelectedEmployees = selectedEmployees.filter((id) => {
    const employee = employeesStore.employees.find((e) => e.id === id);
    return (
      employee &&
      (employee.fullname
        .toLowerCase()
        .includes(debouncedSearchSelected.toLowerCase()) ||
        employee.nickname
          .toLowerCase()
          .includes(debouncedSearchSelected.toLowerCase()))
    );
  });
  const currentEmployees = filteredSelectedEmployees.slice(
    indexOfFirstEmployee,
    indexOfLastEmployee,
  );
  const filteredEmployees = employeesStore.employees.filter(
    (employee) =>
      (employee.fullname &&
        employee.fullname
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase())) ||
      (employee.nickname &&
        employee.nickname
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase())),
  );

  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const input = document.getElementById("searchInput");
      const table = document.getElementById("searchTable");
      if (
        table &&
        !table.contains(event.target) &&
        input &&
        !input.contains(event.target)
      ) {
        setSearch("");
        setShowTable(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectEmployee = (employeeId) => {
    if (!selectedEmployees.includes(employeeId)) {
      setSelectedEmployees((prev) => [...prev, employeeId]);
    }
  };

  const handleRemoveEmployee = (employeeId) => {
    const newSelectedEmployees = selectedEmployees.filter(
      (id) => id !== employeeId,
    );
    setSelectedEmployees(newSelectedEmployees);

    const newFilteredSelectedEmployees = newSelectedEmployees.filter((id) => {
      const employee = employeesStore.employees.find((e) => e.id === id);
      return (
        employee &&
        (employee.fullname
          .toLowerCase()
          .includes(debouncedSearchSelected.toLowerCase()) ||
          employee.nickname
            .toLowerCase()
            .includes(debouncedSearchSelected.toLowerCase()))
      );
    });

    const newPageCount = Math.ceil(
      newFilteredSelectedEmployees.length / itemsPerPage,
    );

    if (currentPage > newPageCount) {
      setCurrentPage(newPageCount > 0 ? newPageCount : 1);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const deptData = await departamentStore.getDepartment(departmentId);

      if (deptData) {
        setDepartment(deptData);
        setSelectedHeads(deptData.heads.map((head) => head.id));
        setSubDeps(deptData.sub_departments);
      }
      const employeesInDept =
        await employeesStore.getEmployeesByDepartment(departmentId);

      if (employeesInDept && employeesInDept.length > 0) {
        setInitialEmployees(employeesInDept.map((emp) => emp.id));
        setSelectedEmployees(employeesInDept.map((emp) => emp.id));
      }
      await usersStore.loadUsers();
      await employeesStore.loadEmployees();
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      await employeesStore.loadEmployees(); // Загружаем всех сотрудников
      
      // Фильтруем сотрудников по departmentId
      const employeesInDept = employeesStore.getEmployeesByDepartment(departmentId);
      
      if (employeesInDept.length > 0) {
        const employeeIds = employeesInDept.map((emp) => emp.id);
        setSelectedEmployees(employeeIds); // Устанавливаем отфильтрованные ID сотрудников в состояние
      }
    };
  
    loadInitialData();
  }, [departmentId, employeesStore]);
  

  const handleAddEmployee = async (newEmployeeData) => {
    try {
      const newEmployee = await employeesStore.addEmployee(newEmployeeData);
      if (newEmployee) {
        setSelectedEmployees([...selectedEmployees, newEmployee.id]); 
        employeesStore.loadEmployees()
      }
      setShowAddEmployeeModal(false);
    } catch (error) {
      console.error("Error adding new employee:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [departmentId]);

  useSetTitle(`Редактировать отдел - ${department.title}`);

  useEffect(() => {
    setShowTable(debouncedSearch.length > 0 && filteredEmployees.length > 0);
  }, [debouncedSearch, filteredEmployees.length]);

  const departmentHeads = usersStore.users.filter(
    (user) => user.profile.role === "department_head",
  );

  const handleAddSubDep = async (event) => {
    event.preventDefault();
    setSubDepErrorMessage(null);
    
    const newSubDepTitle = newSubDep.trim();
    
    if (subDeps.find((dep) => dep.title.toLowerCase() === newSubDepTitle.toLowerCase())) {
      setSubDepErrorMessage("Подотдел с таким названием уже существует");
      return;
    }

    const createdSubDep = await departamentStore.addSubDepartment(departmentId, newSubDepTitle);

    setSubDeps((prev) => [...prev, createdSubDep]);
    setNewSubDep("");
  };

  const handleRemoveSubDep = async () => {
    try {
      setSubDepErrorMessage(null);
      await departamentStore.deleteSubDepartment(departmentId, toDeleteSubDepId);
      if (departamentStore.error) {
        setSubDepErrorMessage(departamentStore.error);
        return;
      }
      setSubDeps((prev) => prev.filter((dep) => dep.id !== toDeleteSubDepId));
    } catch (error) {
      console.error("Error while deleting subdepartment:", error);
    } finally {
      setShowSubDepartmentDeleteModal(false);
      setToDeleteSubDepId(null);
      setToDeleteSubDepTitle(null);
    }
  };

  const handleRemoveSubDepButton = (subDep) => {
    setToDeleteSubDepId(subDep.id);
    setToDeleteSubDepTitle(subDep.title);
    setShowSubDepartmentDeleteModal(true);
  };

  const handleEditSubDep = async (event) => {
    event.preventDefault();
    setEditSubDepErrorMessage(null);

    if (!toEditSubDepTitle.trim()) {
      setEditSubDepErrorMessage("Название подотдела не может быть пустым");
      return;
    }

    if (subDeps.find((dep) => dep.title.toLowerCase() === toEditSubDepTitle.trim().toLowerCase())) {
      setEditSubDepErrorMessage("Подотдел с таким названием уже существует");
      return;
    }

    try {
      await departamentStore.updateSubDepartment(departmentId, toEditSubDepId, toEditSubDepTitle);
      if (departamentStore.error) {
        setEditSubDepErrorMessage(departamentStore.error);
        return;
      }
      setSubDeps((prev) => prev.map((dep) => dep.id === toEditSubDepId ? { ...dep, title: toEditSubDepTitle } : dep));
      setShowSubDepartmentEditModal(false);
      setToEditSubDepId(null);
      setToEditSubDepTitle(null);
      setEditSubDepErrorMessage(null);
    } catch (error) {
      setEditSubDepErrorMessage("Ошибка при обновлении подотдела");
    }
  };

  const handleEditSubDepButton = (subDep) => {
    setEditSubDepErrorMessage(null);
    setToEditSubDepId(subDep.id);
    setToEditSubDepTitle(subDep.title);
    setShowSubDepartmentEditModal(true);
  };

  const handleSelectHead = (headId) => {
    if (!selectedHeads.includes(headId)) {
      setSelectedHeads([...selectedHeads, headId]);
    }
  };

  const handleRemoveHead = (headId) => {
    setSelectedHeads(selectedHeads.filter((id) => id !== headId));
  };

  const handleSaveChanges = async (event) => {
    event.preventDefault();
    const updatedData = {
      title: department.title,
      heads_ids: selectedHeads,
      employee_ids: selectedEmployees,
    };

    try {
      await departamentStore.updateDepartament(departmentId, updatedData);

      const employeesToAdd = selectedEmployees.filter(
        (id) => !initialEmployees.includes(id),
      );
      const employeesToRemove = initialEmployees.filter(
        (id) => !selectedEmployees.includes(id),
      );

      if (employeesToAdd.length > 0) {
        await departamentStore.addEmployeeToDepartament(
          departmentId,
          employeesToAdd,
        );
      }
      if (employeesToRemove.length > 0) {
        await departamentStore.removeEmployeeFromDepartament(
          departmentId,
          employeesToRemove,
        );
      }

      await fetchData();
      setShouldRedirect(true);
    } catch (error) {
      console.error(
        "Error while updating department:",
        error.response || error,
      );
    }
  };

  useEffect(() => {
    if (shouldRedirect) {
      navigate("/departament");
    }
  }, [shouldRedirect]);

  return (
    <PageWrapper key={departmentId}>
      {isLoading ? (
        <div className={style.spinnerCenter}>
          <Spinner />
        </div>
      ) : (
        <>
          <Header text="Редактировать отдел" />
          <Form className={style.formContainer}>
            <div className={style.flexTop}>
              <CustomButton onClick={handleSaveChanges} type="button">
                Сохранить изменения
              </CustomButton>
              <CustomButton
                onClick={() => setShouldRedirect(true)}
                type="button"
              >
                Назад
              </CustomButton>
            </div>

            <FormGroup
              label="Название отдела"
              type="text"
              value={department.title}
              onChange={(e) =>
                setDepartment({ ...department, title: e.target.value })
              }
            />

            <Form.Group>
              <Form.Label>Руководители отдела</Form.Label>

              <Dropdown>
                <Dropdown.Toggle className={style.dropdownContainer}>
                  Выберите руководителей
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {departmentHeads.map((head) => (
                    <Dropdown.Item
                      key={head.id}
                      onClick={() => handleSelectHead(head.id)}
                    >
                      {head.profile.full_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {selectedHeads.length > 0 ? (
                <Header text="Начальники отдела" marginTop="10px" />
              ) : null}
              <div
                className={
                  selectedHeads.length > 0
                    ? style.personsContainer
                    : style.emptyContainer
                }
              >
                {selectedHeads.map((id) => (
                  <div key={id} className={style.personContainer}>
                    {
                      departmentHeads.find((head) => head.id === id)?.profile
                        .full_name
                    }
                    <DeleteButton handleDelete={() => handleRemoveHead(id)} />
                  </div>
                ))}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Подотделы</Form.Label>
              {subDepErrorMessage && (
                <Alert
                  variant="danger"
                  onClose={() => setSubDepErrorMessage(null)}
                  dismissible
                >
                  {subDepErrorMessage}
                </Alert>
              )}
              <div className={style.subDepartmentInputContainer}>
                <Form.Control
                  type="text"
                  placeholder="Введите название подотдела"
                  value={newSubDep}
                  onChange={(e) => setNewSubDep(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleAddSubDep()}
                />
                <CustomButton onClick={handleAddSubDep} disabled={!newSubDep.trim()}>
                  Добавить
                </CustomButton>
              </div>
              <div className={
                subDeps.length > 0
                  ? style.personsContainer
                  : style.emptyContainer
              }>
                {subDeps.map((subDep) => (
                  <div key={subDep.id} className={style.personContainer}>
                    <Container onClick={() => handleEditSubDepButton(subDep)}>
                      <div className={style.content}>{subDep.title}</div>
                    </Container>
                    <DeleteButton handleDelete={() => handleRemoveSubDepButton(subDep) } />
                  </div>
                ))}
              </div>
            </Form.Group>
            
            <Modal show={showSubDepartmentDeleteModal} onHide={() => setShowSubDepartmentDeleteModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Удаление подотдела</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Вы уверены, что хотите удалить подотдел {toDeleteSubDepTitle}?</p>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton onClick={() => setShowSubDepartmentDeleteModal(false)}>
                  Отмена
                </CustomButton>
                <CustomButton 
                  onClick={() => handleRemoveSubDep()}
                  style={{ backgroundColor: "red" }}
                >
                  Удалить
                </CustomButton>
              </Modal.Footer>
            </Modal>

            <Modal show={showSubDepartmentEditModal} onHide={() => setShowSubDepartmentEditModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Редактирование подотдела</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {editSubDepErrorMessage && (
                  <Alert
                    variant="danger"
                    onClose={() => setEditSubDepErrorMessage(null)}
                    dismissible
                  >
                    {editSubDepErrorMessage}
                  </Alert>
                )}
                <Form.Control
                  type="text"
                  value={toEditSubDepTitle}
                  onChange={(e) => setToEditSubDepTitle(e.target.value)}
                />
              </Modal.Body>
              <Modal.Footer>
                <CustomButton onClick={() => setShowSubDepartmentEditModal(false)}>
                  Отмена
                </CustomButton>
                <CustomButton 
                  onClick={(e) => handleEditSubDep(e)}
                  style={{ backgroundColor: "green" }}
                >
                  Сохранить
                </CustomButton>
              </Modal.Footer>
            </Modal>

            <>
              <Header text="Cотрудники отдела" />
              <div className={style.depEmployyesInputAndBtn}>
                <div className={style.allEmpButtonGroup}>
                  <div className={style.flexRow}>
                    <Form.Group>
                      <Form.Label>Добавление сотрудников</Form.Label>
                      <Form.Control
                        id="searchInput"
                        className={style.searchInput}
                        type="text"
                        placeholder="Начните вводить имя"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Form.Group>
                    <button
                      type="button"
                      className={style.clearInputBtn}
                      onClick={() => setSearch("")}
                    >
                      <img
                        className={style.clearInputImg}
                        src={icClose}
                        alt="clear"
                      />
                    </button>
                  </div>
                  <CustomButton
                    onClick={() => setShowAddEmployeeModal(true)}
                    type="button"
                  >
                    Создать нового сотрудника
                  </CustomButton>
                </div>

                {showTable ? (
                  <div className={style.tableScrollWrapper} id="searchTable">
                    <div className={style.posAbsoluteTable}>
                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setShowTable(false);
                        }}
                        className={style.closeTable}
                      >
                        Закрыть таблицу
                      </button>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th className={style.tableSearchInput}>
                              <span> </span>
                              <span>ФИО</span>
                              <span>Никнейм</span>
                              <span>Отдел</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredEmployees.map((employee) => (
                            <tr
                              key={employee.id}
                              onClick={() => handleSelectEmployee(employee.id)}
                            >
                              <td className={style.tableSearchInputEmp}>
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={selectedEmployees.includes(
                                      employee.id,
                                    )}
                                    readOnly
                                  />
                                </span>
                                <span>{employee.fullname}</span>
                                <span>{employee.nickname}</span>
                                <span>
                                  {employee.departments
                                    .map((dept) => dept.title)
                                    .join(", ")}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  debouncedSearch &&
                  filteredEmployees.length === 0 && (
                    <div
                      className={style.posAbsoluteTableNotFound}
                      style={{ color: "white" }}
                    >
                      Сотрудники не найдены
                    </div>
                  )
                )}

                <div className={style.flexEnd}>
                  <Form.Control
                    type="text"
                    placeholder="Поиск"
                    value={searchSelected}
                    onChange={(e) => setSearchSelected(e.target.value)}
                    className={style.searchInput}
                  />
                  <button
                    type="button"
                    className={style.clearInputBtnR}
                    onClick={() => setSearchSelected("")}
                  >
                    <img
                      className={style.clearInputImg}
                      src={icClose}
                      alt="clear"
                    />
                  </button>
                </div>
              </div>
              {currentEmployees.length > 0 ? (
                <div className={style.containerForSelectedTable}>
                  <div className={style.tableScrollWrapper}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className={style.tableHeadSelectedEmp}>
                            <span>ФИО</span>
                            <span>Никнейм</span>
                            <span> </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentEmployees.map((id) => {
                          const employee = employeesStore.employees.find(
                            (e) => e.id === id,
                          );
                          return (
                            <tr key={id}>
                              <td className={style.tableSelectedEmp}>
                                <span>{employee.fullname}</span>
                                <span>{employee.nickname}</span>
                                <span>
                                  <DeleteButton
                                    handleDelete={() =>
                                      handleRemoveEmployee(id)
                                    }
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <PaginationPayment
                      itemsPerPage={itemsPerPage}
                      totalItems={filteredSelectedEmployees.length}
                      paginate={setCurrentPage}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              ) : (
                <p style={{ textAlign: "center" }}>
                  В этом отделе ещё нет сотрудников
                </p>
              )}
            </>
          </Form>
        </>
      )}
      {showAddEmployeeModal && (
        <AddEmployee
          show={showAddEmployeeModal}
          onHide={() => setShowAddEmployeeModal(false)}
          onSave={handleAddEmployee}
        />
      )}
    </PageWrapper>
  );
});

export default DepartmentEdit;
